<template>
  <v-container>
    <div
      v-if="$store.getters.getMobileModel !== 'PDA' && !isDisabled"
      class="qrcode-wrap"
      :style="{display: showQrScaner}"
    >
      <QrCodeScanComponent @scan-result="onPCodeScan" />
    </div>
    <div class="input-content-wrap">
      <v-row
        class="pb-0"
      >
        <v-col
          class="pb-0"
          :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
          :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
        >
          <!--
          <v-text-field
            id="p-code"
            v-model="pCode"
            label="품번"
            clearable
            :disabled="isDisabled"
            @keyup.enter.prevent="onEnterPcode"
          />
        -->
          <v-autocomplete
            id="p-code"
            ref="refPcode"
            v-model="pCode"
            :items="productionInfoList"
            label="품번"
            item-text="품번"
            item-value="품번"
            auto-select-first
            :disabled="isDisabled"
            clearable
            @change="onPCodeChange"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pt-6 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        class="pb-0 pt-0"
      >
        <v-col
          class="pt-0 pb-0"
          cols="12"
        >
          <v-text-field
            id="input-qty"
            v-model="qty"
            autocomplete="off"
            label="수량"
            clearable
            type="number"
            :disabled="isDisabled"
            @keyup.enter.prevent="onEnterQty"
          />
        </v-col>
      </v-row>
    </div>
    <div class="data-grid">
      <dx-data-grid
        ref="refInventoryGrid"
        :data-source="inventoryInfoList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        key-expr="seqId"
        :height="gridHeight"
        :focused-row-key.sync="focusedRowKey"
        @toolbar-preparing="onToolbarPreparing"
        @row-removed="onRowRemoved"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxEditing
          :allow-deleting="true"
          :allow-updating="false"
          :allow-adding="false"
          :use-icons="true"
          mode="row"
        />

        <DxColumn
          :allow-editing="false"
          caption="품번"
          data-field="품번"
        />

        <DxColumn
          :allow-editing="false"
          caption="품명"
          data-field="품명"
        />
        <DxColumn
          :allow-editing="false"
          caption="수량"
          data-field="수량"
          width="60"
        />
        <DxColumn
          width="50"
          type="buttons"
        />
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </div>
  </v-container>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting, DxEditing, DxSearchPanel } from 'devextreme-vue/data-grid'
import AppLib from '../share/AppLib'
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../components/QrCodeScanComponent'

export default {
  name: 'MainPage',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxEditing,
    DxSearchPanel,
    QrCodeScanComponent
  },
  props: {
    type: {
      type: String,
      default: '0'
    }
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      pCode: null,
      qty: null,
      inventoryInfoList: [],
      inventoryPlan: null,
      productionInfoList: [],
      gridHeight: null,
      focusedRowKey: null,
      isDisabled: false,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.refreshPurchase()
    this.refreshInventory()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.setDataGridHeight()
    this.$Q('#p-code').focus()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    setDataGridHeight () {
      const drawContentHeight = this.$Q('.input-content-wrap').height()
      this.gridHeight = this.$Q('html').height() - drawContentHeight - 50
    },
    getText (row) {
      return `${row.품번} (${row.품명})`
    },
    handleResize (e) {
      this.setDataGridHeight()
    },
    initInput () {
      this.pCode = null
      this.qty = null
      setTimeout(() => {
        this.$Q('#p-code').focus()
      }, 100)
    },
    addInventory () {
      console.log(this.pCode)
      if (this.pCode === null || this.pCode.length === 0 || this.qty === null || this.pCode.qty === 0) {
        this.$snotify.error('품번/수량을 입력하여 주세요')
        return
      }
      const temp = {
        품번: this.pCode,
        품목유형: ConstDef.구매품
      }
      this.isLoading = true
      this.$_sp.runFindSqlProc('품목정보', temp)
        .then((data) => {
          const result = this.$_sp.MakeModel(data)
          console.log(result)
          if (result.length === 0) {
            this.$snotify.error('품목정보를 검색할 수 없습니다.')
            this.isLoading = false
          } else {
            this.saveInventory(result)
          }
        })
    },
    saveInventory (goods) {
      const where = this.$_.findWhere(this.productionInfoList, { 품번: this.pCode })
      if (where !== undefined && where.자재유형 === ConstDef.외주직납품) {
        this.$snotify.error('외주직납품은 완/반제품 실사에서 처리해주시기 바랍니다.')
        return
      }
      const temp = {
        실사기준일: this.inventoryPlan[0].실사기준일,
        구분: this.type,
        품번: this.pCode,
        수량: this.qty,
        createid: this.userInfo.userId,
        updateid: this.userInfo.userId,
        품명: goods[0].품명
      }
      this.$_sp.runInsertAndFindSqlProc('재고실사', ['품번', 'createid'], ['품명'], temp)
        .then((data) => {
          this.isLoading = false
          const result = this.$_sp.MakeModel(data)
          console.log(result)
          temp.seqId = result[0].seqId
          this.inventoryInfoList.push(temp)
          this.focusedRowKey = temp.seqId
          this.initInput()
        })
    },
    onEnterPcode (e, pCode) {
      console.log(pCode)
      let val = null
      if (pCode === null || pCode === undefined) {
        val = AppLib.base64DecodeSubstring(this.$Q('#p-code').val()).split('/')
      } else {
        val = pCode.split('/')
      }
      this.pCode = val[0].replace('?', ' ')
      if (pCode === undefined) {
        this.$Q('.v-autocomplete__content').css('display', 'none')
        this.$Q('#p-code').text(val[0])
      } else {
        this.$Q('#p-code').text(val[0])
      }
      this.$Q('#input-qty').focus()
    },
    onPCodeChange (pCode) {
      setTimeout(() => {
        this.showQrScaner = 'none'
        this.$Q('#input-qty').focus()
        this.$Q('.v-autocomplete__content').css('display', 'none')
      }, 300)
    },
    onPCodeScan (pCode) {
      this.onEnterPcode(null, pCode)
    },
    onEnterQty (e) {
      if (this.qty === undefined || this.qty === null || !AppLib.isNumeric(this.qty)) {
        this.$snotify.info('실사 수량을 입력하여 주세요.')
        this.qty = null
        return
      }
      if (this.qty !== null && this.qty.length > 0) {
        this.addInventory()
      }
    },
    onRowRemoved (row) {
      console.log(row)
      this.isLoading = true
      this.$_sp.runDeleteSqlProc('재고실사', [{ seqId: row.data.seqId }])
        .then((data) => {
          this.isLoading = false
        })
    },
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;"><b>재공 실사현황</b></div>'
          }
        }
      )
    },
    async refreshInventory () {
      this.isLoading = true
      await this.$_sp.runFindSqlProc('재고실사계획', { 완료: 'N' })
        .then((data) => {
          this.inventoryPlan = this.$_sp.MakeModel(data)
          if (this.inventoryPlan.length === 0) {
            this.isLoading = false
            this.isDisabled = true
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.isLoading === false) {
        this.$snotify.error('진행중인 실사 계획이 없습니다.')
        return
      }
      this.$_sp.runFindProc('spFindAllInventoryByDateAndUserIdAndType', { 실사기준일: this.inventoryPlan[0].실사기준일, createid: this.userInfo.userId, 구분: this.type })
        .then((data) => {
          this.isLoading = false
          this.inventoryInfoList = this.$_sp.MakeModel(data)
          setTimeout(() => {
            this.$Q('#p-code').focus()
          }, 400)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshPurchase () {
      this.productionInfoList = this.$store.getters.getGoodsList(ConstDef.구매품)
      console.log(this.productionInfoList)
      this.initInput()
      // this.$_sp.runNoEncodeFindSqlProc('품목정보', { 품목유형: 'S0106' })
      //   .then((data) => {
      //     this.productionInfoList = this.$_sp.MakeModel(data)
      //     this.initInput()
      //   })
      //   .catch(error => {
      //     this.initInput()
      //     if (error.response.status === ConstDef.GENERRAL_ERROR) {
      //       this.$snotify.error(error.response.data.message)
      //     } else {
      //       this.$snotify.error(`에러코드 : ${error.response.status}`)
      //     }
      //   })
    }
  }
}
</script>

<style lang="scss">
</style>
