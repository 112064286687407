<template>
  <div>
    <!-- type = 1 은 재공실사 , 0 = 창고재공실사 -->
    <InventoryComponent type="1" />
  </div>
</template>
<script>
import InventoryComponent from './InventoryComponent'

export default {
  name: 'MainPage',
  components: {
    InventoryComponent
  },
  data: function () {
    return {
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {

  },
  updated () {
  },
  methods: {

  }
}
</script>

<style lang="scss">
</style>
